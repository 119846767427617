'use strict';

angular.module('cpformplastApp.items')
  .controller('itemManagementController', function ($scope, DataManager, $state, Notification, Auth) {

    const PreviewModeOnlyRoles = ["salesmen_dept"]
    $scope.items = [];
    $scope.isPreviewMode = false
    $scope.itemTableButtons = [{
      'icon': 'plus',
      'title': 'Ajouter',
      'function': () => {
        $state.go('items/item-management/item-creation');
      }
    }];

    $scope.itemTableStructure = [
      {
        'title': 'Items (moules)',
        'value': 'mold',
        'filter': 'alpha'
      },
      {
        'title': '#client',
        'value': '_clientName',
        'filter': 'alpha'
      },
      {
        'title': 'Moule',
        'value': 'name',
        'filter': 'alpha'
      },
      {
        'title': 'matériel',
        'value': '_itemMateriel',
        'filter': 'alpha'
      },
      {
        'title': '#code',
        'value': 'code',
        'filter': 'alpha'
      },
      {
        'title': 'archive',
        'value': 'archive',
        'filter': 'alpha'
      },
      {
        'title': 'Statut',
        'value': '_isComplete',
        'filter': 'alpha'
      }
    ];

    $scope.setIsPreviewMode = function () {
      Auth.getCurrentUser(function (user, err) {

        $scope.isPreviewMode = PreviewModeOnlyRoles.findIndex(x => x === user.role) !== -1

        if ($scope.isPreviewMode) $scope.itemTableButtons = [];
      })
    }



    $scope.init = function () {
      $scope.setIsPreviewMode();
      DataManager.fetchItems(true).then((data) => {
        $scope.items = data;
        $scope.increaseItem();
      })
        .catch(err => {
          console.log(err);
          Notification.error('Un probème est survenu lors du chargement des items');
        });
    };

    $scope.selectItem = function (item) {
      $state.go('items/item-management/item-creation', {'itemId': item._id});
    };

    $scope.increaseItem = function () {
      for (var i = 0; i < $scope.items.length; i++) {
        $scope.items[i]["_isComplete"] = $scope.items[i].isComplete ? '✔' : '✗';
        $scope.items[i]["_itemMateriel"] = $scope.items[i].category.dimension + ' ' + $scope.items[i].category.name;
        $scope.items[i]["_clientName"] = $scope.items[i].client ? $scope.items[i].client.name : '';
      }
    };

    $scope.init();
  });
